export function animation(){

  let controller = new ScrollMagic.Controller();
  const sceneAdd = (element) => {
    let scene = new ScrollMagic.Scene({triggerElement: element, triggerHook: .8,})
                  .on('enter', ()=>{
                    element.classList.add('action');
                  })
                  .addTo(controller);
  }


  const fadein = document.querySelectorAll('.j-fadein');
  const slide = document.querySelectorAll('.j-slide');

  // fadein.forEach((el, i) => {
  Array.prototype.forEach.call(fadein, (el, i) => {
    sceneAdd(el);
  });
  // slide.forEach((el, i) => {
  Array.prototype.forEach.call(slide, (el, i) => {
    sceneAdd(el);
  });

}
